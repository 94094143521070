.comparison-sidebar {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 1001;
  overflow-y: auto;
  padding: 20px;
  width: 95%;
  background: white;
  margin: 25px;
  margin-right: 100px;
  border-radius: 8px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);

  .region-inputs {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    .region-input {
      flex: 1;
      margin-right: 10px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      select {
        width: 100%;
        padding: 8px;
        border: 1px solid #ccc;
        border-radius: 5px;
        background-color: #fff;
        font-size: 14px;
        color: #333;
        cursor: pointer;

        &:focus {
          outline: none;
          border-color: #007bff;
        }
      }
    }

    .add-region-button {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #007bff;
      color: white;
      border: none;
      padding: 10px;
      border-radius: 10px;
      width: 100px;
      height: 30px;
      font-size: 16px;
      cursor: pointer;
      &:hover {
        background-color: #0056b3;
      }

      &.disabled {
        background-color: #e7e7e7;
      }
    }
  }
  .loader {
    border: 10px solid #f3f3f3; /* Light grey */
    border-top: 10px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 2s linear infinite;

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }

  .charts-container {
    display: flex;
  }
  .close-icon {
    color: #aaa;
    font-size: 24px;
    cursor: pointer;
  }
}
