.flex {
  border: none !important;
  display: flex;
  gap: 10px;
  align-items: center;
  div {
    height: 18px;
    .open-information-icon {
      height: 18px;
      width: 18px;
      background-color: #e1e1e1;
      border-radius: 50%;
      color: #484848;
    }
  }
}
