.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent background */
  z-index: 1000;
  /* Ensure the modal appears on top of everything */
  display: flex;
  justify-content: center;
  align-items: center;

  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    height: unset;
    overflow-y: auto;
    max-height: calc(100vh - 100px);
    /* Drop shadow effect */

    &.width-full {
      width: 95%;
      overflow-x: auto;
    }

    &.loading {
      height: unset;
    }
    .graph-table-popup-container {
      position: relative;

      .close {
        position: absolute;
        cursor: pointer;
        font-size: 24px;
        right: 0;
        color: #aaa;
      }

      img {
        max-width: 1024px;
        display: block;
        margin: 0 auto;
        object-fit: contain;
      }

      .loading-overlay {
        position: absolute;
        width: 100%;
        height: 60%;
        min-height: calc(100vh - 476px);
        min-width: 600px;
        top: 53%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 90px;

        p {
          text-align: center;
          font-size: 30px;
          color: white;
          margin: 0;
        }
      }
    }
  }
}

.slider {
  margin: 5px auto 0px;
}

.region-inputs {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  .region-input {
    flex: 1;
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    select {
      width: 100%;
      padding: 8px;
      border: 1px solid #ccc;
      border-radius: 5px;
      background-color: #fff;
      font-size: 14px;
      color: #333;
      cursor: pointer;

      &:focus {
        outline: none;
        border-color: #007bff;
      }
    }
  }

  .add-region-button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 10px;
    width: 100px;
    height: 30px;
    font-size: 16px;
    cursor: pointer;
    &:hover {
      background-color: #0056b3;
    }
  }
}

.region-container {
  margin-top: 10px;
  display: flex;
  gap: 10px;
  h4 {
    margin: 0;
  }
  .regions {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 5px;
    flex: 1;
    justify-content: space-around;

    .region {
      display: flex;
      gap: 5px;
      align-items: center;

      label {
        margin: 0;
      }
    }
  }
}

.main-table-container {
  padding-top: 20px;
  margin-bottom: 45px;
  display: flex;

  .scrollable-container {
    max-width: 100%;
    overflow-x: auto;
    display: flex;
    gap: 10px;
    flex: 1;
  }

  h3 {
    margin: 0;
    padding-top: 20px;
  }

  th,
  td {
    white-space: nowrap;
  }

  .single-table-container {
    &.flex-1 {
      flex: 1;
    }
    .table-container {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      gap: 20px;
      width: 100%;

      .dataTable {
        width: 100%;
        border-collapse: collapse;
        margin-top: 20px;
        /* Adjust as needed */
        font-size: 14px;

        th,
        td {
          padding: 8px;
          text-align: left;
          border: 1px solid #f5f7fa;
          cursor: unset !important;
        }

        tbody {
          tr {
            td:last-child {
              word-break: break-word;
            }
          }
          td {
            &:has(.flex) {
              min-width: 380px;
              max-height: 35px;
              padding: 0;
            }
          }
        }
        th {
          background-color: #f5f7fa;
        }
        tr {
          &:nth-child(even) {
            background-color: #f5f7fa;
            /* Set the background color for even rows */
          }
          &:nth-child(odd) {
            background-color: #fff;
            /* Set the background color for odd rows */
          }
        }
      }
      .flex {
        display: flex;
        align-items: center;
        gap: 5px;
        height: 34px;
        padding-left: 10px;
        .open-information-icon {
          height: 18px;
          width: 18px;
          background-color: #e1e1e1;
          border-radius: 50%;
          color: #484848;
          cursor: pointer;
        }
      }
    }
  }
}

.indicator-info-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 10px;
  .heading {
    font-weight: 600;
  }
  p {
    font-size: 16px;
    margin: 0;
    &.bold {
      font-weight: 700;
    }
  }
}
