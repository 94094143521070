.container {
  a {
    text-decoration: none;
  }
  .image-container {
    width: 76px;
    height: 76px;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
    border-radius: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    background-color: white; // Ensure there is a background color to cover the line if necessary
    position: relative; // Create a stacking context for the z-index to work
    z-index: 3; // Ensure this is higher than the z-index of the line

    img {
      width: 45px;
      height: 45px;
      object-fit: contain;
    }

    &.active {
      background-color: #990000;
    }

    &.m0 {
      margin: unset;
    }
  }

  .title {
    margin-top: 14px;
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    color: #990000;

    @media (min-width: 1537px) {
      font-size: 16px;
    }
  }
}
