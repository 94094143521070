.legends-container {
  position: absolute;
  right: -20px;
  z-index: 1000;
  padding: 20px 12px;
  margin-right: 20px;
  margin-top: 80px;
  border-radius: 15px;
  top: -80px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  button {
    min-width: 120px;
    padding: 10px 17px;
    background-color: #fff;
    border: none;
    font-size: 18px;
    color: #484848;
    font-family: "Source Sans Pro";
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 11.8px;
    cursor: pointer;
    border-radius: 5px;
    margin-bottom: 18px;

    svg {
      fill: currentColor;
    }

    &.active {
      color: #990000;
      background-color: #f7eaea;
    }

    &:hover {
      color: #990000;
      background-color: #f7eaea;
    }
  }
}
