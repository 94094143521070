.sidebar-button {
  position: absolute;
  top: 15px;
  left: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &.open {
    left: 436px;
  }
  &.close {
    left: -3px;
  }

  svg {
    width: 40.25px;
    height: 60.38px;
  }
}

.floating-buttons {
  position: absolute;
  bottom: 197px;
  right: 11px;
  z-index: 1000;
  svg {
    cursor: pointer;
  }
}

.main-sidebar {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 500 !important;
  overflow-y: auto;
  padding: 23px;
  width: 400px;
  background: white;
  background: #fff;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  height: calc(100vh - 135px);

  .open-information-icon {
    height: 21px;
    width: 21px;
    border-radius: 50%;
    color: #484848;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
    cursor: pointer;
  }

  .sidebar_search {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
    position: relative;
    justify-content: end;
    position: sticky;
    top: 0;
    z-index: 10;

    input {
      width: 100%;
      font-weight: bold;
      border: 0.5px solid #9d9d9d;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      flex: 1 1;
      padding: 9px;
      color: #9d9d9d;
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      border-radius: 0;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      height: 39.87px;
      box-sizing: border-box;
      transform: translateY(-1px);
      outline: none;
    }

    button {
      width: 67.984px;
      height: 39.875px;
      flex-shrink: 0;
      background-color: #9d9d9d;
      border: 0.5px solid #9d9d9d;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      color: white;
      transform: translateY(-1px);
      cursor: pointer;
    }

    .options-container {
      position: absolute;
      top: 45px;
      max-width: 352px;
      background-color: #fff;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      z-index: 10;
    }
  }

  .sidebar_direction {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .sidebar_transport_filters {
    background-color: #f8f8f8;
    padding: 21px;
    margin-top: 24px;
    display: flex;
    gap: 18px;
    flex-direction: column;

    .sidebar_transport-heading {
      color: #000;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }

  .categories-and-filters-container {
    overflow-y: auto;
    margin-top: 24px;

    .sidebar_categories {
      .railway-section {
        padding: 0 10px 20px;
        display: flex;
        align-items: center;
        gap: 5px;
        .radius-input {
          width: 100%;
          font-weight: bold;
          border: 0.5px solid #9d9d9d;
          border-radius: 5px;
          padding: 9px;
          color: #9d9d9d;
          font-size: 14px;
          font-style: normal;
          font-weight: 300;
          line-height: normal;
        }

        button {
          min-width: 75px;
          background-color: #990000;
          border: none;
          outline: none;
          padding: 8px;
          font-size: 16px;
          font-weight: 500;
          color: white;
          border-radius: 20px;
          cursor: pointer;

          &:hover {
            background-color: #9d9d9d;
          }

          &:disabled {
            background-color: #9d9d9d;
            cursor: not-allowed;
          }
        }
      }

      display: flex;
      flex-direction: column;
      gap: 18px;
      padding: 21px;
      flex-shrink: 0;
      background-color: #f8f8f8;

      .sidebar_categories_header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        svg {
          cursor: pointer;
        }

        .categories-heading {
          color: #000;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
        }

        .tooltip {
          position: relative;
          display: inline-block;
          .tooltiptext {
            visibility: hidden;
            max-width: 130px;
            width: max-content;
            color: #fff;
            background-color: #8b4e21;
            text-align: center;
            padding: 5px;
            border-radius: 6px;
            position: absolute;
            z-index: 1;
            top: -35px;
            left: -10px;
          }

          &:hover {
            .tooltiptext {
              visibility: visible;
            }
          }
        }
      }
    }

    .sidebar_maintenance {
      background-color: #f8f8f8;
      margin-top: 24px;
      /* height: 217px; */

      .sidebar_maintenance_text {
        color: #484848;
        padding-left: 21px;
        padding-bottom: 21px;
      }
    }
  }

  .scroll {
    overflow: auto;
    margin-top: 20px;
  }
}

.tooltip-container {
  background: #990000;
  width: 100px;
  transform: translate(-45px, -45px);
  padding: 8px;
  border-radius: 6px;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    bottom: -10px;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #990000 transparent transparent transparent;
  }

  p {
    margin: 0;
    color: white;
  }
}
