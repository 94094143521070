.information-popup-container {
  padding: 36px;
  background-color: #fff;
  position: absolute;
  left: 200px;
  max-width: 700px;
  fill: #fff;
  filter: drop-shadow(0px 0px 30px rgba(0, 0, 0, 0.6));
  top: 20px;
  z-index: 1000;
  max-height: calc(100vh - 200px);
  overflow-y: auto;

  p {
    margin: 0;
  }

  .header {
    display: flex;
    justify-content: space-between;

    svg {
      cursor: pointer;
    }

    .heading {
      color: #000;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }

  .details {
    margin-top: 50px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;

    &.block {
      display: block;
    }
  }
}
