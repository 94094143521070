.top-bar {
  height: 90px;
  background: white;
  width: 100%;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  position: relative;

  .logo {
    position: fixed;
    top: 10px;
    left: 150px;
    height: auto;
    z-index: 1000;

    &.absolute {
      position: absolute;
    }

    img {
      width: 160px;
      cursor: pointer;
    }
  }
}
