.CookieConsent {
  background: #f8f8f8 !important;
  color: black !important;
  border-top: 1px solid #e7e7e7 !important;
  z-index: 2000 !important;
}

.CookieConsent button {
  background: transparent !important;
  border: none !important;
  padding: 10px 15px !important;
  font-size: 14px !important;
  border: 1px solid #990000 !important;
  transition: all 0.2s ease-in-out;
  color: #990000 !important;
  font-weight: 600 !important;
  margin: 0 !important;
}

.CookieConsent button:nth-child(2) {
  margin: 0 15px !important;
}


.CookieConsent button:hover {
  background: #990000 !important;
  color: white !important;
}