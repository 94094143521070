.data-source-container {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 999;
  padding: 10px 20px;
  background-color: rgba(255, 255, 255, 0.7);

  p,
  a {
    margin: 0;
    font-size: 12px;

    &.red {
      color: #990000;
    }
  }

  a {
    display: block;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}
