.logos-container {
  position: absolute;
  z-index: 500;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);

  .images-container {
    display: flex;
    gap: 20px;
    .single-img {
      background: white;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 40px;
      .img {
        width: 80px;
        height: 70px;
        display: block;
        margin: 0 auto;
        object-fit: contain;
        &.small {
          width: 40px;
        }

        @media (min-width: 1920px) {
          width: 100px;
        }
      }
    }
    .row {
      display: flex;
      gap: 5px;
    }
  }
}
