.transport-emission-container {
  padding: 19px 18px 25px;
  box-shadow: 0px 5.62px 21.07px 0px rgba(0, 0, 0, 0.1);
  margin: 38px 25px;

  p {
    margin: 0;
    color: #484848;
    text-align: start;
  }

  .transport-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .icon-and-details {
    display: flex;
    gap: 11px;
    align-items: center;
  }

  .icon-container {
    width: 44px;
    height: 44px;
    box-shadow: 0px 5.62px 21.07px 0px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }

  .heading {
    font-size: 16px;
    line-height: 20.11px;
    font-weight: 600;

    &.red,
    .total-carbon-emissions p {
      color: #910000;
    }
  }

  .transport-emission {
    margin-top: 26px;
  }

  .route-container {
    display: flex;
    margin-left: 55px;
    justify-content: space-between;
  }

  .total-carbon-emissions {
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;

    .value {
      font-size: 15px;
      line-height: 25px;
    }
  }

  .sub-heading {
    margin-top: 14px;
    font-size: 14px;
    line-height: 17.6px;
    margin-bottom: 22px;
  }

  .details {
    .value {
      margin-top: 13px;
      font-size: 15px;
      line-height: 18.86px;
      color: #990000;
    }
  }
}
