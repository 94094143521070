.single-information-container {
  display: flex;
  gap: 20px;

  .icon-container {
    background-color: #fff;
    padding: 10px;
    min-width: 50px;
    height: 50px;
    filter: drop-shadow(0px 3px 15px rgba(0, 0, 0, 0.15));
    display: flex;
    justify-content: center;
    align-items: center;

    .image {
      width: 30px;
      height: 30px;
      object-fit: contain;
      mix-blend-mode: luminosity;
    }
  }

  .detail-container {
    display: flex;
    justify-content: start;
    flex-direction: column;
    gap: 13px;

    .heading {
      text-align: left;
      color: #000;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .description {
      color: #484848;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;

      .link {
        color: unset;
        color: #990000;
        text-decoration: underline;

        span {
          text-decoration: none;
        }

        &:hover {
          text-decoration: none;
          cursor: pointer;
        }
      }
    }
  }
}
