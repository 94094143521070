@keyframes moveAround {
  0% {
    transform: translate(0, 0);
  }
  20% {
    transform: translate(-10px, 0); /* Move left */
  }
  25% {
    transform: translate(-10px, 0); /* Pause slightly */
  }
  45% {
    transform: translate(-10px, 10px); /* Move down */
  }
  50% {
    transform: translate(-10px, 10px); /* Pause slightly */
  }
  70% {
    transform: translate(0, 10px); /* Move right */
  }
  75% {
    transform: translate(0, 10px); /* Pause slightly */
  }
  95% {
    transform: translate(0, 0); /* Move back to original position */
  }
  100% {
    transform: translate(0, 0); /* Pause slightly */
  }
}

.container {
  border: 0.42px dashed;
  width: 79px;
  height: 79px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 39.5px;
  animation: moveAround 2.5s linear infinite; /* Continuous looping animation */
  position: relative;

  .inner-circle {
    width: 60px;
    height: 60px;
    border-radius: 30px;
    box-shadow: 0px 3.36px 12.62px 0px #00000026;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
  }
}
