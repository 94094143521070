.emission-distance-main-container {
  padding: 20px 18px;
  box-shadow: 0px 5.62px 21.07px 0px rgba(0, 0, 0, 0.1);

  p {
    margin: 0;
  }

  .heading {
    font-weight: 600;
    font-size: 16px;
    line-height: 20.11px;
    text-align: start;
  }

  .icon-and-details {
    display: flex;
    gap: 11px;
    align-items: center;
    margin-top: 18px;
  }

  .icon-container {
    width: 44px;
    height: 44px;
    box-shadow: 0px 5.62px 21.07px 0px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }

  .details {
    .heading {
      font-size: 15px;
      font-weight: 600;
      line-height: 18.86px;
    }

    .sub-heading {
      font-size: 13px;
      line-height: 16.34px;
      font-weight: 400;
      margin-top: 5px;

      span {
        color: #ac3030;
      }
    }
  }

  .slider-main-container {
    .heading {
      font-size: 13.5px;
      font-weight: 600;
      line-height: 16.97px;
      margin-top: 28.7px;
    }

    .divider {
      margin-top: 19px;
      background: linear-gradient(90deg, #01ad49 0%, #ff8085 100%);
      height: 1px;
      margin-bottom: 15px;
    }

    .low-high-emissions {
      display: flex;
      justify-content: space-between;

      .emission-container {
        display: flex;
        gap: 6.59px;
        align-items: center;

        .bullet {
          width: 6.54px;
          height: 6.54px;
          border-radius: 50%;
        }

        .bullet {
          width: 6px;
          height: 6px;
          border-radius: 50%;
          transform: translateY(1px);
        }

        .green {
          background: #01ad49;
        }

        .red {
          background: #eb1922;
        }
      }

      .text-green {
        color: #01ad49;
        font-size: 12px;
        line-height: 15.08px;
      }

      .text-red {
        color: #eb1922;
        font-size: 12px;
        line-height: 15.08px;
      }
    }
  }
}
