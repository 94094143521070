.open-railway-legend {
  max-height: 500px;
  position: absolute;
  top: 75px;
  left: -307px;
  margin: 0 !important;

  iframe {
    border: none;
    max-height: calc(100vh - 350px);
    overflow-y: scroll;
    box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
  }
}

.railway-table-container {
  margin-top: 30px;
  border-collapse: collapse !important;
  width: 100% !important;

  th,
  td {
    border: 1px solid #ddd !important;
    padding: 8px !important;
  }

  tr:nth-child(even) {
    background-color: #f2f2f2 !important;
  }

  tr:hover {
    background-color: #ddd !important;
  }

  th {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
    text-align: left !important;
    background-color: #112255 !important;
    color: white !important;
    padding-left: 15px !important;
  }
}
