.category-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #fff;
  filter: drop-shadow(0px 4px 15px rgba(0, 0, 0, 0.15));
  padding: 10px;

  p {
    margin: 0;
  }

  .open-information-icon {
    height: 21px;
    width: 21px;
    border-radius: 50%;
    color: #484848;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
  }

  .category-content {
    display: flex;
    gap: 10px;
    align-items: center;
    padding-left: 10px;

    .icon {
      margin-top: 5px;
      cursor: pointer;
    }

    input[type="checkbox"] {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      width: 20px;
      height: 20px;
      border-radius: 50%; // Ensures the checkbox remains rounded
      border: 1px solid #ccc;
      cursor: pointer;
      position: relative;
      margin: 0;

      &::before {
        content: "";
        position: absolute;
        top: 45%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 10px;
        height: 3px;
        border-style: solid;
        border-color: transparent;
        border-width: 0px 0px 2px 2px;
        visibility: hidden;
      }

      &:checked {
        background-color: #990000;
        box-shadow: 0 0 5px #990000; // Adding drop shadow

        &::before {
          visibility: visible;
          transform: translate(-50%, -50%) rotate(-45deg); // Corrected rotation for tick icon
          border-color: white;
        }
      }
    }

    .label-container {
      display: flex;
      align-items: center;
      gap: 5px;

      &:has(.icon) {
        cursor: pointer;
      }
    }
    .category-assets {
      width: 40px;
      height: 40px;
      border-radius: 25px;
      box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 25px;
        height: 25px;
        object-fit: contain;
        mix-blend-mode: luminosity;
      }
    }

    .tooltip {
      flex: 1;
      display: flex;
      justify-content: end;
      height: 21px;

      svg {
        cursor: pointer;
      }
    }
  }

  .open {
    display: block;
  }

  .hide {
    display: none;
  }
}
