.image-container {
  position: relative;
  border: 0.42px solid #f9eeee;
  background-color: #fefcfc;
  width: 734.27px;
  height: 734.27px;
  border-radius: 367px;
  display: flex;
  align-items: center;
  justify-content: center;

  .inner-circle {
    width: 620px;
    height: 620px;
    border-radius: 310px;
    border: 0.84px solid rgba(153, 0, 0, 0.2);
    background-color: #fcf8f8;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .grey-map {
      width: 511px;
      height: 511px;
      display: block;
      margin: 0 auto;
    }

    .green-map {
      position: absolute;
    }
  }
}
