.sidebar {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 1001;
  overflow-y: auto;
  padding: 20px;
  width: 30%;
  background: white;
  margin: 25px;
  border-radius: 8px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);

  .sidebar-header {
    align-items: end;
    margin-bottom: 20px;

    h2 {
      margin: 0;
    }
  }

  .heading {
    text-align: center;
  }

  .address {
    width: 50px;
    border-bottom: 3px solid #990000;
    padding: 0px 0px 5px 0px;
  }

  .address-line {
    padding: 5px;
    .bold {
      font-size: 16px;
      font-weight: bold;
    }
  }
  .data-table {
    border: 10px;
    background: #f5f7fa;
  }
  .close-icon {
    color: #aaa;
    font-size: 24px;
    cursor: pointer;
    float: right;
  }
}

.regions {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 15px;
  max-width: 90%;

  .region {
    display: flex;
    gap: 5px;
    align-items: center;

    label {
      margin: 0;
    }
  }
}

.indicator-info-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 10px;
  .heading {
    font-weight: 600;
  }
  p {
    margin: 0;
    &.bold {
      font-weight: 700;
    }
  }
}
