.select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  font-size: 14px;
  color: #333;
  cursor: pointer;

  &:focus {
    outline: none;
    border-color: #007bff;
  }
}
