.container {

  .note {
    font-weight: 600;
    span {
      color: #990000;
    }
  }

  .dataTable {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    /* Adjust as needed */
    font-size: 14px;
  
    th,
    td {
      padding: 8px;
      text-align: left;
      border: 1px solid #f5f7fa;
    }
  
    tbody {
      tr {
        td:last-child {
          word-break: break-word;
        }
      }
    }
    th {
      background-color: #f5f7fa;
    }
    tr {
      &:nth-child(even) {
        background-color: #f5f7fa;
        /* Set the background color for even rows */
      }
      &:nth-child(odd) {
        background-color: #fff;
        /* Set the background color for odd rows */
      }
    }
  
    &.terminal-table {
      margin-top: 0;
      border: none;
  
      th {
        padding: 14px 0;
      }
  
      td,
      th {
        background-color: #f9f9f9;
        border: none;
        padding: 0;
      }
  
      tr,
      thead {
        &.border {
          border-bottom: 1px solid #cfcfcf;
        }
      }
      tbody {
        tr {
          &.border:last-child {
            border-bottom: none;
          }
        }
      }
      tr {
        td {
          &.text-capitialize {
            text-transform: capitalize;
          }
        }
      }
    }
  }
  
}

