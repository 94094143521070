.all-categories {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  .open-information-icon {
    height: 21px;
    width: 21px;
    background-color: #e1e1e1;
    border-radius: 50%;
    color: #484848;
  }
}
