.buttons-container {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;

  .button {
    border: 1px solid rgb(4, 136, 219);
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
    background-color: white;

    &:hover,
    &.active {
      background-color: rgb(4, 136, 219);
      color: white;
    }
  }
}
