.wrapper {
  color: #555;
  font-size: 20px;
  position: relative;
  text-align: center;
  .tooltip {
    background: #fff;
    padding: 10px 15px;
    color: #484848;
    display: block;
    margin-bottom: 15px;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    bottom: 100%;
    white-space: nowrap;
    min-width: 100%;
    box-shadow: 2px 2px 30px 0px #00000033;
    border-radius: 5px;
    font-size: 18px;
    left: 50%;
    transform: translateX(-50%);

    &::before {
      bottom: -20px;
      content: " ";
      display: block;
      height: 20px;
      left: 0;
      position: absolute;
      min-width: 100%;
    }

    &:after {
      border-left: solid transparent 10px;
      border-right: solid transparent 10px;
      border-top: solid #fff 10px;
      bottom: -10px;
      content: " ";
      top: 42px;
      height: 0;
      left: 50%;
      transform: translateX(-50%);
      position: absolute;
      width: 0;
    }
  }
  &:hover {
    .tooltip {
      opacity: 1;
      pointer-events: auto;
      transform: translate(-50%, -15px);
    }
  }
}
