.custom-slider-container {
  margin-top: 19px;

  .relative {
    position: relative;
    margin-top: 60px;
  }

  .range-lines {
    position: relative;
    height: 18px;
    display: flex;
    align-items: center;
  }

  .slider {
    -webkit-appearance: none !important;
    width: 65%;
    position: relative !important;
    z-index: 3 !important;
    border-left: 1px solid #01ad49 !important;
    border-right: 1px solid #01ad49 !important;
    background: transparent !important;

    &::-webkit-slider-thumb {
      -webkit-appearance: none !important;
      height: 18px !important;
      width: 18px !important;
      background: url("../../../public/images/input-slider.png") no-repeat
        center center !important;
      cursor: pointer !important;
      border-radius: 50% !important;
    }

    &::-webkit-slider-runnable-track {
      -webkit-appearance: none !important;
      color: #13bba4 !important;
      margin-top: -1px !important;
    }

    &::before {
      position: absolute !important;
      top: 8px !important;
      left: 0 !important;
      right: 0 !important;
      height: 1px !important;
      content: "" !important;
      background-image: linear-gradient(
        to right,
        #01ad49 33%,
        rgba(255, 255, 255, 0) 0%
      ) !important;
      background-position: bottom !important;
      background-size: 15px !important;
      background-repeat: repeat-x !important;
      z-index: -1 !important;
    }
  }

  .remaining {
    background-image: linear-gradient(
      to right,
      #cccccc 33%,
      rgba(255, 255, 255, 0) 0%
    );
    background-position: bottom;
    background-size: 15px;
    background-repeat: repeat-x;
    flex: 1;
    height: 1px;
  }

  .tool-tip-container {
    position: absolute;
    top: -50px;
    transform: translateX(-18%);

    img {
      filter: drop-shadow(rgba(0, 0, 0, 0.24) 0px 3px 5px);
    }

    .tool-tip-details {
      display: flex;
      align-items: center;
      gap: 4px;
      display: inline-flex;
      align-items: center;
      padding: 4px 9px;
      background-color: #fff;
      filter: drop-shadow(rgba(0, 0, 0, 0.1) 0px 4px 12px);
      border-radius: 20px;
      margin-bottom: 6px;
      position: relative;

      &::after {
        content: " ";
        position: absolute;
        top: 100%;
        left: 30%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: white transparent transparent transparent;
      }

      p {
        font-size: 13px;
        line-height: 22.59px;
        color: #01ad49;
      }
    }
  }
}

.marks-container {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;

  .mark {
    font-size: 12px;
    line-height: 15.08px;
  }
}

.scale-main-container {
  display: flex;
  flex-direction: column;

  .scale-container {
    display: flex;
    justify-content: space-between;
    margin-top: 11px;
    margin-left: 3px;

    .short-line {
      position: relative;
      flex: 1;
      justify-content: space-between;
      border-left: 1px solid #cccccc;
      height: 7px;

      &::after {
        position: absolute;
        top: 1.5px;
        left: 3px;
        right: 0;
        height: 4px;
        content: "";
        background: repeating-linear-gradient(
          to right,
          #cccccc 0,
          #cccccc 1px,
          transparent 1px,
          transparent 5.4px
        );
        z-index: -1;
      }
    }

    .mark.translate {
      transform: translate(4px);
    }
  }
}
