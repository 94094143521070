.transport-volume-table-main-container {
  max-height: 383px;
  overflow-y: auto;
  .transport-volume-table-container {
    border-collapse: collapse;
    width: 100%;

    th,
    td {
      border: 1px solid #ddd;
      padding: 8px;
    }

    tr:nth-child(even) {
      background-color: #f2f2f2;
    }

    tr:hover {
      background-color: #ddd;
    }

    th {
      padding-top: 12px;
      padding-bottom: 12px;
      text-align: left;
      background-color: #112255;
      color: white;
      padding-left: 15px;
      .flex {
        display: flex;
        align-items: center;
        gap: 10px;
        .open-information-icon {
          height: 18px;
          width: 18px;
          background-color: #e1e1e1;
          border-radius: 50%;
          color: #484848;
          cursor: pointer;
        }
      }
    }
  }
}

.select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  font-size: 14px;
  color: #333;
  cursor: pointer;
  margin-bottom: 10px;

  &:focus {
    outline: none;
  }
}
