.leaflet-container {
    width: 100% !important;
    height: 100vh !important;
}

.leaflet-container .grayscale {
    filter: grayscale(100%);
}

.leaflet-map {
    border: none !important;
}

.leaflet-map .geojson-layer {
    filter: grayscale(0%);
}



.leaflet-container .colorful-map {
    mix-blend-mode: darken;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
    /* Horizontal offset, vertical offset, blur radius, color */
}

.map-container {
    position: relative;
    /* Make the container relative */
    height: calc(100vh - 130px);
    overflow: hidden;
}

.message-popup {
    position: absolute;
    right: 0;
    z-index: 1000;
    background-color: wheat;
    padding: 20px;
    margin-right: 20px;
    margin-top: 80px;
    border-radius: 15px;
}

.message-popup span {
    font-size: 14px;
}

.checkbox-container {
    position: absolute;
    /* Make the checkbox container absolute */
    top: 20px;
    right: 20px;
    /* Move the container to the center horizontally */
    display: flex;
    /* Make the container a flex container */
    flex-direction: row;
    /* Arrange items in a row */
    z-index: 1000;
    /* Ensure the checkboxes are above the map */
}

.checkbox-label {
    display: block;
    margin-right: 16px;
    /* Add margin for spacing between checkboxes */
    position: relative;
    cursor: pointer;
}

.checkbox-custom {
    position: absolute;
    top: 0;
    left: 0;
    width: 18px;
    height: 18px;
    background-color: #fff;
    border: 2px solid #4caf50;
    border-radius: 3px;
    transition: background-color 0.3s;
}

.checkbox-label input {
    opacity: 0;
    cursor: pointer;
}

.checkbox-label span {
    margin-left: -10px;
}

.checkbox-label input:checked~.checkbox-custom {
    background-color: #4caf50;
}

.leaflet-map {
    flex: 1;
    height: 400px;
    /* Adjust the height as needed */
    border: 2px solid #ccc;
}

.show-more {
    border: none;
    padding: 0;
    background: none;
    color: red;
    cursor: pointer;
}

/* skeleton loader checkbox */
.skeleton-checkbox-loader {
    display: inline-block;
}

.skeleton-checkbox {
    width: 18px;
    height: 18px;
    background-color: #dbd9e4;
    margin-right: 8px;
    display: inline-block;
    animation: loading 1s infinite ease-in-out;
    border: 2px solid #dbd9e4;
    border-radius: 3px;
}

.skeleton-label {
    width: 27px;
    height: 10px;
    background-color: #dbd9e4;
    margin-right: 8px;
    display: inline-block;
    animation: loading 1s infinite ease-in-out;
    border: 2px solid #dbd9e4;
    border-radius: 3px;
    position: absolute;
    top: 4px;
}


@keyframes loading {
    0% {
        opacity: 0.7;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0.7;
    }
}

/* Compass */

.leaflet-compass.leaflet-control {
    margin: 0 !important;
    bottom: 240px;
    width: 30px;
    right: 16px;
    background-color: transparent !important;
    box-shadow: none !important;
}

.leaflet-compass .compass-icon {
    width: 100% !important;
    height: 100% !important;
    background: url('../../../public/images/compass-icon.png') center !important;
}

/* My Location */

.my-current-location-container .icon-container {
    border-radius: 5px;
    background: #FFF;
    width: 35px;
    height: 35px;
    position: absolute;
    right: 5px;
    z-index: 1000;
    bottom: 225px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}


/* Region Details */

.leaflet-popup-content-wrapper:has(table) {
    width: 500px !important;
}

.leaflet-popup-content-wrapper .leaflet-popup-content:has(table) {
    width: unset !important;
}


.leaflet-popup-content-wrapper .leaflet-popup-content ul {
    list-style: none;
    padding-left: 0;
}

.leaflet-popup-content-wrapper .leaflet-popup-content ul li {
    list-style: none;
    padding-left: 0;
    padding: 8px;
    border: 1px solid #d9d9d9;
    border-top: none;
    margin: 0 !important;
}

.leaflet-popup-content-wrapper .leaflet-popup-content ul li:first-child {
    border-top: 1px solid #d9d9d9;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.leaflet-popup-content-wrapper .leaflet-popup-content ul li:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}


/* tooltip chart-js */

.chartjs-tooltip {
    white-space: pre-line !important;
}

/* Transport volume info icon popup*/

.content {
    font-size: 16px;
}