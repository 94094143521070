.autocomplete-container {
  position: relative;

  .autocomplete-input {
    width: 85%;
    font-weight: bold;
    border: 0.5px solid #9d9d9d;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    flex: 1 1;
    padding: 9px;
    color: #9d9d9d;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    border-radius: 0;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  .autocomplete-suggestions {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 1;
    background-color: #fff;
    border-top: none;
    border-radius: 0 0 4px 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    list-style-type: none;
    margin: 0;
    padding: 0;

    .autocomplete-suggestion {
      padding: 8px;
      cursor: pointer;

      &:hover {
        background-color: #f5f5f5;
      }
    }
  }
}
