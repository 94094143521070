@font-face {
    font-family: 'Source Sans Pro'; /* Name the font whatever you like */
    src: url('./assets/fonts/source-sans-pro.otf') format('opentype'); /* Adjust the path accordingly */
}

body {
    margin: 0;
    font-family: 'Source Sans Pro';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}
