.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* semi-transparent black background */
  z-index: 1500;
  /* Ensure the popup is on top of other elements */
  display: flex;
  justify-content: center;
  align-items: center;

  .popup-content {
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    max-width: 90%;
    max-height: 90%;
    overflow: auto;
    min-width: 1200px;

    .popup-close {
      float: right;
      color: #aaa;
      font-size: 24px;
      cursor: pointer;
    }
    .popup-chart {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: space-between;
      position: relative;

      .chart-container {
        width: 898px;
        height: 500px;
        margin: 0 auto;

        &.h-unset {
          height: unset;
        }
      }

      .loading-overlay {
        position: absolute;
        width: 100%;
        height: 60%;
        min-height: calc(100vh - 476px);
        min-width: 600px;
        top: 55%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 90px;
      }

      .loading-container {
        p {
          text-align: center;
          font-size: 30px;
          color: white;
          margin: 0;
        }
      }

      .dataTable {
        width: 100%;
        border-collapse: collapse;
        margin-top: 20px;
        /* Adjust as needed */
        font-size: 14px;

        th,
        td {
          padding: 8px;
          text-align: left;
          border: 1px solid #f5f7fa;
        }

        tbody {
          tr {
            td:last-child {
              word-break: break-word;
            }
          }
        }
        th {
          background-color: #f5f7fa;
        }
        tr {
          &:nth-child(even) {
            background-color: #f5f7fa;
          }
          &:nth-child(odd) {
            background-color: #fff;
          }
        }
      }
    }

    img {
      margin-bottom: 20px;
    }
  }
}

.region-container {
  display: flex;
  gap: 10px;
  h4 {
    margin: 0;
  }
}
