.sidebar {
  display: inline-block;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  left: 0;
  margin: 25px;
  padding: 30px;
  position: absolute;
  top: 0;
  width: 30%;
  z-index: 1001;
  p {
    margin: 0;
  }
  &.sidebar-terminal {
    fill: #fff;
    filter: drop-shadow(0px 0px 30px rgba(0, 0, 0, 0.6));
    border-radius: 0;
    width: 631px;
    left: 20px;
    margin: 0px;
    padding: 30px;
    top: 20px;
    &.to-right {
      left: unset;
      right: 20px;
    }
    border-radius: 10px;

    .sidebar-header {
      p {
        font-size: 18px;
        font-weight: 600;
      }
      &.terminal {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 31px;
      }
    }
  }
  .kv-terminal-table-container {
    padding: 10px 23px;
    background-color: #f9f9f9;
  }
  .close-icon {
    color: #aaa;
    cursor: pointer;
    font-size: 24px;
    cursor: pointer;
  }
}
