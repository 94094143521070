.sidebar {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 1001;
  overflow-y: auto;
  padding: 20px;
  width: 30%;
  background: white;
  margin: 25px;
  border-radius: 8px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);

  // &.w-unset {
  //   width: unset;
  //   min-width: 30%;
  // }
  .sidebar-header {
    margin-bottom: 20px;

    .close-icon {
      color: #aaa;
      cursor: pointer;
      font-size: 24px;
    }
  }
}
