.charts-container {
  display: flex;
  flex-wrap: wrap;

  .chart {
    max-width: 700px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin: 16px;
    padding: 16px;
    width: calc(33.33% - 32px);
  }
}
