.line-chart-container {
  margin-bottom: 50px;
  max-width: 1000px;

  .regions {
    margin-top: 20px;
    display: flex;
    align-items: center;
    gap: 20px;
    text-transform: capitalize;
    margin-left: 25px;

    .single-region {
      display: flex;
      gap: 5px;
      align-items: center;
      .circle {
        width: 5px;
        height: 5px;
        border-radius: 10px;
      }
    }
  }
}
