.container {
  position: absolute;
  z-index: 600;
  bottom: 63px;
  left: 8.7px;
  background-color: rgba(255, 255, 255, 0.8);
  width: 140px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;


  p {
    margin: 0;
    font-size: 18px;
    color: #990000;
    line-height: 21px;
    font-weight: 600;
  }

  &.open {
    left: 454.7px;
  }
}
