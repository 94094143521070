.feedback-container {
  position: absolute;
  z-index: 600;
  bottom: 9px;
  left: 8.7px;
  text-decoration: none;

  &.open {
    left: 454.7px;
  }

  button {
    max-width: 140px;
    padding: 12px;
    background-color: rgba(255, 255, 255, 0.8);
    border: none;
    outline: none;
    text-decoration: none;
    font-size: 18px;
    font-weight: 600;
    display: flex;
    gap: 12.5px;
    align-items: center;
    cursor: pointer;  
    color: #484848;
    line-height: 21px;
    border-radius: 5px;

    svg {
      min-width: 18.5px;
      min-height: 16.92px;
    }
  }
}