.container {
  p {
    margin: 0;
  }

  .section-1 {
    .heading {
      font-size: 30px;
      font-weight: 600;
      span {
        color: #990000;
      }
    }
    .sub-title {
      margin-top: 17px;
      font-size: 18px;
      color: #484848;

      &.mb {
        margin-top: unset;
        margin-bottom: 10px;
      }
    }
    .scrolling-content {
      max-height: 155px;
      overflow-y: scroll;
      padding-right: 20px;

      // Hide scrollbar for Webkit-based browsers (Chrome, Safari)
      &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }
      // Hide scrollbar for Firefox
      scrollbar-width: none; // Firefox
      -ms-overflow-style: none; // IE and Edge
    }
  }

  .section-2 {
    margin-top: 46px;
    .heading {
      font-size: 32px;
      font-weight: 600;
    }
    .sub-title {
      margin-top: 17px;
      font-size: 18px;
      color: #484848;
    }
  }

  .section-3 {
    margin-top: 23px;
    display: flex;
    position: relative;
    width: 575px;

    @media (min-device-width: 1537px) {
      width: 730px;
    }

    input {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      flex: 1;
      width: 100%;
      padding: 18px 0 18px 47px;
      font-size: 16px;
      color: #9d9d9d;
      border: 1px solid #d9d9d9;
      outline: none;
      box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
      position: relative;
      z-index: 6;
    }

    button {
      min-width: 140px;
      height: 57px;
      background-color: #484848;
      color: white;
      border: none;
      outline: none;
      font-size: 18px;
      font-weight: 700;
      cursor: pointer;
      text-transform: uppercase;
      position: relative;
      z-index: 6;
    }

    img {
      position: absolute;
      left: 26px;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 7;
    }

    .dropdown {
      position: absolute;
      top: 55px;
      font-size: 14px;
      background: white;
      box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
      box-sizing: border-box;
      left: 1px;
      z-index: 4;
      width: 434px;
      max-height: 250px;
      overflow-y: auto;
      &.loading {
        min-height: 50px;
        padding: 10px;
      }
      p {
        display: block;
        margin: 10px 0 10px 10px;
        padding: 5px;
        &.selected,
        &:hover {
          background: #d9d9d9;
          cursor: pointer;
        }
      }
      @media (min-device-width: 1537px) {
        min-width: 589px;
      }
    }
  }

  .section-4 {
    margin-top: 20px;
    position: relative;

    .line {
      min-width: 550px;
      position: absolute;
      left: 20px;
      top: 20%;
      z-index: 1; // Ensure this is lower than the z-index of the cards

      @media (min-device-width: 1537px) {
        top: 40%;
        left: 45%;
        min-width: 600px;
        transform: translate(-50%, -50%);
      }
    }

    .cards-container {
      display: flex;
      gap: 60px;
      position: relative;
      z-index: 2;

      @media (min-device-width: 1537px) {
        gap: 76px;
      }
    }
  }
}
