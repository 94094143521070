.dataTable {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  /* Adjust as needed */
  font-size: 14px;

  th,
  td {
    padding: 8px;
    text-align: left;
    border: 1px solid #f5f7fa;
    border-top: none;
  }

  tbody {
    tr {
      td:last-child {
        word-break: break-word;
      }
    }
  }
  th {
    background-color: #f5f7fa;
  }
  tr {
    &:nth-child(even) {
      background-color: #f5f7fa;
      /* Set the background color for even rows */
    }
    td {
      cursor: pointer;
    }
    &:nth-child(odd) {
      background-color: #fff;
      /* Set the background color for odd rows */
    }
  }

  &.terminal-table {
    margin-top: 0;
    border: none;

    th {
      padding: 14px 0;
    }

    td,
    th {
      background-color: #f9f9f9;
      border: none;
      padding: 0;

      div {
        display: flex;
        padding: 14px 0;
        align-items: center;
        p {
          margin: 0;
        }
        svg {
          transform: translateY(5px);
        }
      }
    }

    tr,
    thead {
      &.border {
        border-bottom: 1px solid #cfcfcf;
      }
    }
    tbody {
      tr {
        &.border:last-child {
          border-bottom: none;
        }
      }
    }
    tr {
      td {
        &.text-capitialize {
          text-transform: capitalize;
        }
      }
    }
    td {
      cursor: unset;
      div {
        &.value {
          p {
            color: #000;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 26px;
            text-transform: capitalize;
          }
        }
      }
      &.p-right {
        div {
          color: #000;
          font-size: 14px;
          font-style: normal;
          font-weight: 300;
          line-height: 26px;
          text-transform: capitalize;
        }
      }
    }
  }
}
