.contact-details-container {
  display: flex;
  align-items: center;

  .left,
  .right {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 50%;
    .single-detail {
      display: flex;
      gap: 5px;

      .icon {
        width: 17px;
        height: 15px;

        img {
          width: 17px;
          height: 15px;
          object-fit: contain;
        }
      }
      .title {
        font-size: 12px;
        color: rgba(0, 0, 0, 0.5);
        word-break: break-word;

        @media (min-width: 1537px) {
          font-size: 14px;
        }
      }

      &.bold {
        .icon {
          width: 20px;
          height: 20px;

          img {
            width: 20px;
            height: 20px;
            object-fit: contain;
          }
        }
        .title {
          color: #000;
        }
      }
    }
  }

  .right {
    .single-detail {
      margin-left: 30px;
    }
  }

  .line {
    width: 2px;
    height: 60px;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
  }
}
