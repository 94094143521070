.footer-container {
  background-color: #fff;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.2);

  p,a {
    margin: 0;
    color: #484848;
    font-size: 15px;
  }

  .content {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin: 0 auto;
    padding: 9.75px 0;

    .pages {
      display: flex;
      .page {
        padding: 0 10px;
        text-decoration: none;
        cursor: pointer;
        &.border {
          border-left: 1px solid #484848;
          border-right: 1px solid #484848;
        }
        svg {
          transform: translateY(2px);
        }
      }
    }
  }
}
