.sidebar-tab {
  width: 120px;
  height: 47px;
  border: 0.5px solid #e1e1e1;
  border-radius: 8px;
  filter: drop-shadow(5px 5px 10px rgba(153, 0, 0, 0.1));
  display: flex;
  justify-content: center;
  align-items: center;

  .tab-text {
    color: #595d69;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    padding: 1px;
    cursor: pointer;
  }

  &.active-tab {
    background: rgba(153, 0, 0, 0.07);
    border-color: #900;

    .tab-text {
      color: #900 !important;
    }
  }
}
