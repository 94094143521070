.leaflet-control-zoom.leaflet-bar.leaflet-control:has(.leaflet-control-zoom-in) {
    position: absolute;
    bottom: 175px;
    right: 0;
}

.leaflet-bar.easy-button-container.leaflet-control:has(button) {
    position: absolute;
    top: -270px;
    right: 12px;
    margin: 0;
}

.easy-button-container.leaflet-control {
    border: none !important;
}

.easy-button-button.leaflet-bar-part {
    position: relative;
}

.easy-button-button.leaflet-bar-part span {
    display: flex;
    align-items: center;
}

.leaflet-compass .compass-alert {
    right: 30px !important;
    bottom: 0px !important;
}

.leaflet-touch .leaflet-control-geosearch .results.active:after,
.leaflet-control-geosearch .results.active {
    border: none;
}

.leaflet-control-geosearch .results.active {
    margin: 5px;
}

.leaflet-control-geosearch form {

    display: none;
    position: fixed;
    top: 112px;
    left: 22px;
    margin: auto;
    box-shadow: unset;
    border-bottom: 2px solid #d7dce1;
    width: 100%;
    max-width: 335px;
    padding: 0;
    border: none;
    z-index: 1001;
}

.leaflet-control-geosearch form input {
    width: 100%;
    font-weight: bold;
    border: 0.5px solid #9d9d9d;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    flex: 1 1;
    padding: 9px;
    color: #9d9d9d;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    border-radius: 0;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.leaflet-control-geosearch form button {
    display: none;
}

.leaflet-control-geosearch form input {
    height: 40px;
}

.leaflet-control-geosearch form input::placeholder {
    --tw-text-opacity: 1;
    font-size: 16px;
}

.leaflet-control-geosearch .leaflet-bar-part {
    display: none;
}