.sidebar_transport_filters {
  padding: 10px;
  margin: 15px auto;
  display: flex;
  gap: 18px;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px,
    rgba(0, 0, 0, 0.05) 0px 5px 10px;
  border-radius: 5px;
  width: 90%;

  .sidebar_transport-heading {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  .filters {
    background-color: #fff;
    gap: 18px;
    display: flex;
    flex-direction: column;

    .heading {
      font-weight: 600;
      padding: 0 10px;
      margin: 0;
      text-decoration: underline;
    }

    .filter {
      background-color: #f8f8f8;
      border-radius: 25px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      justify-content: space-between;

      .filter-data {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 5px;
      }

      .fitler-content {
        display: flex;
        align-items: center;
        gap: 9px;

        .filter-name {
          font-size: 14px;
          &.pointer {
            cursor: pointer;
          }
        }

        .filter-image {
          width: 38px;
          height: 38px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50px;
          flex-shrink: 0;
          background-color: #fff;
          filter: drop-shadow(0px 4px 15px rgba(0, 0, 0, 0.12));

          img {
            &.width-and-height {
              width: 25px;
              height: 25px;
              object-fit: contain;
            }
          }
        }

        .filter-fields {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 16px;
        }
      }

      .filter-values {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        background-color: #f8f8f8;
        padding: 4px 10px;

        .filter-value {
          display: inline-flex;
          padding: 4px 10px;
          justify-content: center;
          align-items: center;
          gap: 4px;
          border-radius: 5px;
          border: 0.5px solid #9d9d9d;
          background: #fff;
          cursor: pointer;
        }
        .selected-filter {
          color: red;
          border-radius: 5px;
          border: 0.5px solid #900;
          background: rgba(153, 0, 0, 0.05);
          box-shadow: 5px 5px 5px 0px rgba(153, 0, 0, 0.05);
        }
      }
      input[type="checkbox"] {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        width: 18px;
        height: 18px;
        border-radius: 4px;
        border-radius: 2px;
        background: #fff;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        cursor: pointer;
        position: relative;
        transform: translateY(2px);

        &::before {
          content: "";
          position: absolute;
          top: 40%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 10px;
          height: 3px;
          border-style: solid;
          border-color: transparent;
          border-width: 0px 0px 2px 2px;
          visibility: hidden;
        }
        &:checked {
          background-color: #990000;
          box-shadow: 0 0 5px #990000;
          &::before {
            visibility: visible;
            transform: translate(-50%, -50%) rotate(-45deg);
            /* Corrected rotation for tick icon */
            border-color: white;
          }
          /* Adding drop shadow */
        }
      }
      .filter-selector {
        margin-left: 20px;

        input[type="checkbox"] {
          &.line {
            background-color: #990000;
            box-shadow: 0 0 5px #990000;

            &::before {
              position: absolute;
              top: 50%;
              visibility: visible;
              width: 10px;
              height: 2px; /* Adjust the height to create a line */
              background-color: white; /* Set the color of the line */
              border: none; /* Remove the border */
              transform: translate(-50%, -50%); /* Center the line */
            }
          }
        }
      }
    }
  }
}

.slider-container {
  margin-left: 58px;
  width: 70%;
  p {
    margin: 0;
  }

  .min-max-value-container {
    margin: 10px 0;
    display: flex;
    flex-direction: column;
    gap: 5px;

    div {
      display: flex;
      gap: 5px;
      align-items: center;
    }

    .input-and-close {
      display: flex;
      gap: 5px;

      &.ml {
        margin-left: 3px;
      }

      input {
        width: 100%;
        font-weight: bold;
        border: 0.5px solid #9d9d9d;
        border-radius: 5px;
        padding: 9px;
        color: #9d9d9d;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
      }

      p {
        cursor: pointer;
      }

      .close-icon {
        color: #aaa;
        cursor: pointer;
        font-size: 24px;
      }
    }
  }
}
