.map-style-container {
  background-color: #f8f8f8;
  padding: 21px;
  margin-top: 24px;
  display: flex;
  gap: 18px;
  flex-direction: column;

  p {
    margin: 0;
  }

  .heading {
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  .options-container {
    background-color: #fff;
    filter: drop-shadow(0px 4px 15px rgba(0, 0, 0, 0.15));
    padding: 21px;
    gap: 18px;
    display: flex;
    flex-direction: column;

    .single-option-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 5px;
    }
  }
}
