.tables-container {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  gap: 20px;

  .dataTable {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    /* Adjust as needed */
    font-size: 14px;

    th,
    td {
      padding: 8px;
      text-align: left;
      border: 1px solid #f5f7fa;
    }

    tbody {
      tr {
        td:last-child {
          word-break: break-word;
        }
      }
    }
    th {
      background-color: #f5f7fa;
    }
    tr {
      &:has(td) {
        cursor: pointer;
      }
      &:nth-child(even) {
        background-color: #f5f7fa;
        /* Set the background color for even rows */
      }
      &:nth-child(odd) {
        background-color: #fff;
        /* Set the background color for odd rows */
      }
    }
    .flex {
      border: none !important;
      display: flex;
      gap: 10px;
      align-items: center;
      justify-content: space-between;
      .info-icon-container {
        height: 18px;
        .open-information-icon {
          height: 18px;
          width: 18px;
          background-color: #e1e1e1;
          border-radius: 50%;
          color: #484848;
        }
      }
    }
  }
}

.indicator-info-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 10px;
  .heading {
    font-weight: 600;
  }
  p {
    margin: 0;
    &.bold {
      font-weight: 700;
    }
  }
}
