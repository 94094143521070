.language-switcher {
  text-align: end;
  padding-top: 31.5px;
  display: flex;
  justify-content: end;

  .buttons-container {
    border: 0.8px solid #e1e1e1;
    display: flex;
    align-items: center;
    gap: 5px;
    justify-content: space-around;
    border-radius: 6px;
    margin-right: 60px;
    padding: 5px 10px;

    button {
      height: 35px;
      opacity: 1;
      border: none;
      background: none;
      cursor: pointer;
      border-radius: 6px;
      display: flex;
      align-items: center;
      overflow: hidden;

      img {
        transform: translateY(3px);
      }

      p {
        margin: 0;
        line-height: 0.5;
      }

      &:hover {
        background: #f3f3f3;
      }
    }

    .active {
      background: #f3f3f3 !important;
    }
  }
}
