.main-container {
  min-height: calc(100vh - 160px);
  .welcome-page-container {
    width: 95%;
    margin: 30px auto 0;
    .grid {
      display: grid;
      grid-template-columns: 650px 1fr;
      margin-bottom: 10px;

      @media (min-device-width: 1537px) {
        grid-template-columns: 1fr 1fr;
      }

      .left-section {
        max-width: 600px;
        @media (min-device-width: 1537px) {
          max-width: 95%;
        }
      }

      .img {
        object-fit: contain;
        display: block;
        margin-left: auto;
        max-width: 650px;
        max-height: 650px;

        @media (min-device-width: 1537px) {
          max-width: 735px;
          max-height: 735px;
        }
      }
    }
  }
}
