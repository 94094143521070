.modal-main-container {
  z-index: 1200;
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  .modal-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    max-width: 500px;
    margin: 20px;
    padding: 49px 32px 40px;
    z-index: 6000;
    max-height: 90vh;

    &.remove-max-width {
      max-width: unset;
    }

    .close-icon {
      color: #aaa;
      font-size: 50px;
      cursor: pointer;
      width: 20px;
      height: 20px;
      position: absolute;
      right: 32px;
      top: 0;
    }
  }
}
