.outer-border {
  width: 20px;
  height: 20px;
  border: 2px solid currentColor;
  border-radius: 14px;
  position: relative;
  cursor: pointer;
  margin: 0;
  padding: 0;
  color: #e7e7e7;

  &:hover,
  &.checked {
    color: #990000;
  }

  .inner-circle {
    position: absolute;
    width: 17px;
    height: 17px;
    border-radius: 11px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: currentColor;
  }
}
