.main-container {
  margin-top: 24px;
  overflow-y: auto;

  &.remove {
    margin-top: unset;
    max-height: calc(100vh - 410px);
  }

  .legend-container {
    background-color: #f8f8f8;
    padding: 21px;

    &.remove {
      background-color: unset;
      padding: unset;
    }

    &.mt {
      margin-top: 24px;
    }

    p {
      margin: 0;
    }

    .heading {
      color: #000;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }

    .details {
      margin-top: 18px;
      background-color: rgba(255, 255, 255, 0.65);
      padding: 21px;
      display: flex;
      flex-direction: column;
      gap: 18px;

      &.remove {
        margin-top: unset;
      }

      .single-detail {
        iframe {
          border: none;
        }
        .values-container {
          display: flex;
          flex-direction: column;
          gap: 8px;

          .label-and-color {
            display: flex;
            align-items: center;
            gap: 8px;

            .color {
              width: 20px;
              height: 2px;
              display: flex;
              align-items: center;

              img {
                width: 20px;
                height: 20px;
                object-fit: contain;
              }
            }
          }
        }
      }
    }
  }
}
