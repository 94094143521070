.main-container {
  .container {
    margin-top: 23px;
    background-color: rgba(249, 249, 249, 1);
    border-radius: 20px;
    p {
      margin: 0;
    }

    .content-container {
      display: flex;
      align-items: stretch;

      .left {
        border-right: 1px solid rgba(0, 0, 0, 0.1);
      }

      .left,
      .right {
        width: 50%;
        display: flex;
        flex-direction: column;

        .single-card {
          display: flex;
          gap: 20px;
          border-top: 1px solid rgba(0, 0, 0, 0.1);
          align-items: stretch;
          padding-top: 7.5px;
          padding-left: 11px;

          &:first-child {
            border-top: none;
          }

          .icon-container {
            min-width: 31px;
            height: 31px;
            box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.16);
            background: white;
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              width: 20px;
              height: 20px;
              object-fit: contain;
            }
          }

          .details {
            display: flex;
            flex-direction: column;
            gap: 11px;
            padding-right: 10px;
            padding-bottom: 2px;
            .heading {
              font-size: 15px;
            }

            .value {
              font-size: 12px;
              font-weight: 300;
              color: rgba(0, 0, 0, 0.5);

              @media (min-width: 1537px) {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }

  .source{
    margin-top: 10px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.5);
    b {
      color: #000;
    }
  }
}
