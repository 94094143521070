.tooltip-main-container {
  display: flex;
  align-items: center;
  gap: 10px;

  .base-year-container {
    display: flex;
    width: 155px;
    align-items: center;
    gap: 5px;
    border: 1px solid #d9d9d9;
    padding: 8px;
    background-color: #f8f9fa;
    cursor: pointer;

    .base-year-text {
      white-space: nowrap;
    }
  }

  p {
    margin: 0;
  }

  .tooltip-container {
    background: rgb(4, 136, 219);
    width: 44px;
    transform: translate(-15px, -45px);
    padding: 8px;
    border-radius: 6px;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      bottom: -10px;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: rgb(4, 136, 219) transparent transparent transparent;
    }

    p {
      margin: 0;
      color: white;
    }
  }
}
